import { getAxiosByUrl } from 'Services/axios'

const coingeckoUrl = 'https://api.coingecko.com/api/v3'

const getPrice = async (coinId) => {
  const axios = getAxiosByUrl(coingeckoUrl)

  let price = 0
  try {
    const response = await axios.get(`/simple/price?ids=${coinId}&vs_currencies=usd`)
    price = response.data[coinId]['usd']
  } catch (error) {
    console.warn("Can't get price for", coinId)
  }

  return price
}

const getPricesForArray = async (coinIds) => {
  const axios = getAxiosByUrl(coingeckoUrl)

  let prices = {}
  try {
    const ids = coinIds.join(',')
    const response = await axios.get(`/simple/price?ids=${ids}&vs_currencies=usd`)
    prices = response.data
  } catch (error) {
    console.warn("Can't get prices for", coinIds)
  }

  return prices
}

export { getPrice, getPricesForArray }
