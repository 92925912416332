import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import InfrastructureImage from 'Assets/img/infrustructure.webp'
import MonitoringImage from 'Assets/img/monitoring.webp'
import ReputationImage from 'Assets/img/reputation.webp'
import PaperCustom from 'Components/PaperCustom'
import * as React from 'react'

const AdvantagesGrid = () => (
  <PaperCustom
    flexDirection="column"
    sx={(theme) => ({
      padding: {
        md: theme.spacing(10),
      },
    })}
    gap={5}
  >
    <Typography
      variant="h2"
      sx={{
        fontSize: {
          xs: '2rem',
          sm: '3.75rem',
        },
      }}
    >
      Why NODEJUMPER?
    </Typography>
    <Typography
      sx={{
        textAlign: 'justify',
      }}
    >
      Nodejumper is an experienced POS validator and staking provider, running over 40 chains on top-level hardware,
      contributing to Cosmos Ecosystem open-source projects, and offering valuable data analytics, scripting tools &
      services that have earned recognition from prominent crypto projects.
    </Typography>
    <Grid
      container
      spacing={5}
      paddingTop={2}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
      >
        <Box
          display="flex"
          justifyContent="center"
          marginBottom={1}
        >
          <Box
            component="img"
            src={ReputationImage}
            height="70px"
            width="70px"
          />
        </Box>
        <Typography
          variant="h5"
          sx={{
            paddingBottom: '10px',
          }}
        >
          Reputation
        </Typography>
        <Typography
          sx={{
            textAlign: 'justify',
          }}
        >
          We are trusted to be a genesis validator by many crypto projects and supported by their foundational
          delegation
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
      >
        <Box
          display="flex"
          justifyContent="center"
          marginBottom={1}
        >
          <Box
            component="img"
            src={InfrastructureImage}
            height="70px"
            width="70px"
          />
        </Box>
        <Typography
          variant="h5"
          sx={{
            paddingBottom: '10px',
          }}
        >
          Decentralized secure infrastructure
        </Typography>
        <Typography
          sx={{
            textAlign: 'justify',
          }}
        >
          Our servers spread across the globe with the best security possible
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
      >
        <Box
          display="flex"
          justifyContent="center"
          marginBottom={1}
        >
          <Box
            component="img"
            src={MonitoringImage}
            height="70px"
            width="70px"
          />
        </Box>
        <Typography
          variant="h5"
          sx={{
            paddingBottom: '10px',
          }}
        >
          24 / 7 Monitoring
        </Typography>
        <Typography>99% uptime across all networks secured by our validator leading to optimal performance</Typography>
      </Grid>
    </Grid>
  </PaperCustom>
)

export default AdvantagesGrid
