import axios from 'axios'

const axiosMap = {}

export const getAxios = (chainName, apiUrl) => {
  if (axiosMap[chainName]) {
    return axiosMap[chainName]
  } else {
    const baseUrl = apiUrl ? apiUrl : `https://rest.nodejumper.io/${chainName}`
    const chainAxios = axios.create({
      baseURL: baseUrl,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Accept: 'application/json',
      },
      timeout: 5000,
    })
    axiosMap[chainName] = chainAxios

    return chainAxios
  }
}

export const getAxiosByUrl = (url) => {
  if (axiosMap[url]) {
    return axiosMap[url]
  } else {
    const axiosByUrl = axios.create({
      baseURL: url,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Accept: 'application/json',
      },
      timeout: 5000,
    })
    axiosMap[url] = axiosByUrl

    return axiosByUrl
  }
}
