import DarkModeIcon from '@mui/icons-material/DarkMode'
import WbSunnyIcon from '@mui/icons-material/WbSunny'
import IconButton from '@mui/material/IconButton'
import { useThemeContext } from 'Theme/ThemeContext'

const ThemeSwitcher = () => {
  const { theme, toggleTheme } = useThemeContext()
  const isDarkMode = theme.palette.mode === 'dark'

  const handleThemeChange = () => {
    toggleTheme()
  }

  return (
    <IconButton onClick={handleThemeChange}>
      {isDarkMode ? <WbSunnyIcon color="primary" /> : <DarkModeIcon color="primary" />}
    </IconButton>
  )
}

export default ThemeSwitcher
