import { useEffect, useRef, useState } from 'react'
import { getChainApr } from 'Services/chainApi'

export const NJ_APP_PREFIX = 'https://app.nodejumper.io'
const RESTAKE_URL_PREFIX = 'https://restake.app'
const ASSETS_URL_PREFIX = 'https://raw.githubusercontent.com/nodejumper-org/jumper-assets/master/images/chains'

export const getRestakeUrl = (chainRegistryName, valoper) => `${RESTAKE_URL_PREFIX}/${chainRegistryName}/${valoper}`

export const getApr = async (chainRegistryName, hardcodedApr, apiUrl) => {
  const chainApr = await getChainApr(chainRegistryName, apiUrl)

  if (chainApr.calculatedApr) {
    return chainApr.calculatedApr
  } else if (chainApr.estimatedApr) {
    return chainApr.estimatedApr
  } else {
    return hardcodedApr
  }
}

export const getDisplayApr = async (chainRegistryName, hardcodedApr, apiUrl) => {
  const apr = await getApr(chainRegistryName, hardcodedApr, apiUrl)
  return apr ? (apr * 100).toFixed(1) : 0
}

export const getImageUrl = (imageName) => `${ASSETS_URL_PREFIX}/${imageName}`

export const scrollToElement = (elementId) => {
  const element = document.getElementById(elementId)
  if (element) {
    window.scrollTo({
      top: document.getElementById(elementId).offsetTop,
      left: 0,
      behavior: 'smooth',
    })
  } else {
    console.warn(`Can't find element with id: ${elementId}`)
  }
}

export const usePersistedState = (name, defaultValue) => {
  const storedValue = localStorage.getItem(name)
  const [value, setValue] = useState(storedValue !== null ? storedValue : defaultValue)
  const nameRef = useRef(name)

  useEffect(() => {
    try {
      localStorage.setItem(name, value)
    } catch {}
  }, [name, value])

  useEffect(() => {
    const lastName = nameRef.current
    if (name !== lastName) {
      try {
        localStorage.setItem(name, value)
        nameRef.current = name
        localStorage.removeItem(lastName)
      } catch {}
    }
  }, [name, value])

  return [value, setValue]
}
