import TwitterIcon from '@mui/icons-material/Twitter'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import SvgIcon from '@mui/material/SvgIcon'
import Typography from '@mui/material/Typography'
import { ReactComponent as MediumIcon } from 'Assets/icon/medium.svg'
import PaperCustom from 'Components/PaperCustom'
import * as React from 'react'

import { MENTION_TYPES } from './index'

const MentionPreviewCard = ({ mention: { type, author, description, url, logo } }) => {
  let platformIcon
  switch (type) {
    case MENTION_TYPES.TWITTER:
      platformIcon = <TwitterIcon fontSize="large" />
      break
    case MENTION_TYPES.MEDIUM:
      platformIcon = (
        <SvgIcon fontSize="large">
          <MediumIcon />
        </SvgIcon>
      )
      break
    default:
      platformIcon = null
  }

  return (
    <Link
      href={url}
      target="_blank"
      underline="none"
    >
      <PaperCustom
        flexDirection="column"
        justifyContent="space-between"
        gap={3}
        sx={{
          minHeight: '420px',
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          gap={1}
        >
          {platformIcon && platformIcon}
          <Box
            component="img"
            src={logo}
          />
          <Typography>{author}</Typography>
        </Box>
        <Typography>{description}</Typography>
        <Button variant="outlined">Read more</Button>
      </PaperCustom>
    </Link>
  )
}

export default MentionPreviewCard
