import { getAxios, getAxiosByUrl } from 'Services/axios'

const isDevEnv = process.env.NODE_ENV === 'development'
const YEAR_MILLIS = 31536000000
let chains

export const getSupportedChains = async () => {
  if (chains) {
    return chains
  }

  const apiUrl = isDevEnv ? process.env.REACT_APP_API_URL : 'https://api.nodejumper.io'
  const axios = getAxiosByUrl(apiUrl)

  let chainsResponseData = []
  try {
    const response = await axios.get('/api/v1/chains')
    chainsResponseData = response.data
  } catch (error) {
    console.warn('Cant get supported chains')
  }

  chains = chainsResponseData.filter((chain) => chain.network_type !== 'testnet' && !chain.is_archive)
  return chains
}

export const getValidatorStatistic = async () => {
  const apiUrl = isDevEnv ? process.env.REACT_APP_API_URL : 'https://api.nodejumper.io'
  const axios = getAxiosByUrl(apiUrl)

  let validatorStatistic = []
  try {
    const response = await axios.get('/api/v1/validator/statistic')
    validatorStatistic = response.data
  } catch (error) {
    console.warn('Cant get validator statistic')
  }

  return validatorStatistic
}

export const getDirectoryChain = async (path) => {
  const axios = getAxiosByUrl('https://chains.nodejumper.io')

  let chainsResponseData
  try {
    const response = await axios.get(path)
    chainsResponseData = response.data.chain
  } catch (error) {
    console.warn('Cant get directory chain', path)
  }

  return chainsResponseData
}

export const getDirectoryChainApr = async (path) => {
  const axios = getAxiosByUrl('https://chains.nodejumper.io')

  let chainResponseData
  try {
    const response = await axios.get(path)
    chainResponseData = response.data.chain
  } catch (error) {
    console.warn('Cant get directory chain', path)
  }

  return {
    calculated_apr: chainResponseData.params.calculated_apr || 0,
    estimated_apr: chainResponseData.params.estimated_apr || 0,
  }
}

// const getInflationRate = async (chainRegistryId, apiUrl) => {
//   const axios = getAxios(chainRegistryId, apiUrl)
//   let inflationRate = 0
//
//   try {
//     const response = await axios.get('/cosmos/mint/v1beta1/inflation')
//     inflationRate = Number(response.data.inflation)
//   } catch (error) {
//     console.warn('Cant get Inflation rate for ', chainRegistryId)
//   }
//
//   return inflationRate
// }

const getAnnualProvisions = async (chainRegistryId, apiUrl) => {
  const axios = getAxios(chainRegistryId, apiUrl)
  let annualProvisions = 0

  try {
    const response = await axios.get('/cosmos/mint/v1beta1/annual_provisions')
    annualProvisions = Number(response.data.annual_provisions)
  } catch (error) {
    console.warn('Cant get Annual provisions for ', chainRegistryId)
  }

  return annualProvisions
}

const getBondedTokens = async (chainRegistryId, apiUrl) => {
  const axios = getAxios(chainRegistryId, apiUrl)
  let bondedTokens = 0

  try {
    const response = await axios.get('/cosmos/staking/v1beta1/pool')
    bondedTokens = Number(response.data.pool.bonded_tokens)
  } catch (error) {
    console.warn('Cant get Bonded tokes for ', chainRegistryId)
  }

  return bondedTokens
}

const getCommunityTax = async (chainRegistryId, apiUrl) => {
  const axios = getAxios(chainRegistryId, apiUrl)
  let communityTax = 0

  try {
    const response = await axios.get('/cosmos/distribution/v1beta1/params')
    communityTax = Number(response.data.params.community_tax)
  } catch (error) {
    console.warn('Cant get Community tax for ', chainRegistryId)
  }

  return communityTax
}

const getBlocksPerYear = async (chainRegistryId, apiUrl) => {
  const axios = getAxios(chainRegistryId, apiUrl)
  let blocksPerYear = 0

  try {
    const response = await axios.get('/cosmos/mint/v1beta1/params')
    blocksPerYear = Number(response.data.params.blocks_per_year)
  } catch (error) {
    console.warn('Cant get Block per year for ', chainRegistryId)
  }

  return blocksPerYear
}

const getBlocksPerYearActual = async (chainRegistryId, apiUrl) => {
  const axios = getAxios(chainRegistryId, apiUrl)
  let blockPerYearActual

  try {
    const response = await axios.get('/blocks/latest')
    const block1 = response.data.block.header
    const blockRange = Number(block1.height) > 10000 ? 10000 : block1.height - 1

    const response1 = await axios.get('/blocks/' + (Number(block1.height) - blockRange))
    const block2 = response1.data.block.header
    const blockMillis = (new Date(block1.time) - new Date(block2.time)) / blockRange

    blockPerYearActual = Math.ceil(YEAR_MILLIS / blockMillis)
  } catch (error) {
    console.warn('Cant get Block per year real for ', chainRegistryId)
  }

  return blockPerYearActual
}

export const getChainApr = async (chainRegistryId, apiUrl) => {
  const [annualProvisions, bondedTokens, communityTax, blocksPerYear, blocksPerYearActual] = await Promise.all([
    getAnnualProvisions(chainRegistryId, apiUrl),
    getBondedTokens(chainRegistryId, apiUrl),
    getCommunityTax(chainRegistryId, apiUrl),
    getBlocksPerYear(chainRegistryId, apiUrl),
    getBlocksPerYearActual(chainRegistryId, apiUrl),
  ])

  const estimatedApr = (annualProvisions / bondedTokens) * (1 - communityTax)

  if (blocksPerYear) {
    const calculatedApr = estimatedApr * (blocksPerYearActual / blocksPerYear)
    return { estimatedApr, calculatedApr }
  } else {
    return { estimatedApr, calculatedApr: estimatedApr }
  }
}

export const getValidatorInfo = async (chainName, valoper, exponent, apiUrl) => {
  const api = getAxios(chainName, apiUrl)

  let commissionRate = 0
  let bondedTokens = 0
  let status = 'unknown'
  try {
    const response = await api.get(`/cosmos/staking/v1beta1/validators/${valoper}`)
    const validator = response.data.validator
    commissionRate = Number(validator.commission.commission_rates.rate)
    bondedTokens = Number(validator.tokens) / Math.pow(10, exponent)
    status = validator.status
  } catch (e) {
    console.warn("Can't get validator info for ", chainName)
  }

  return {
    commissionRate,
    bondedTokens,
    status: status,
  }
}

export const getDelegatorsCount = async (chainName, valoper, apiUrl) => {
  const api = getAxios(chainName, apiUrl)

  let delegatorsCount = 0
  try {
    const response = await api.get(
      `/cosmos/staking/v1beta1/validators/${valoper}/delegations?pagination.count_total=true`
    )
    delegatorsCount = Number(response.data.pagination.total)
  } catch (e) {
    console.warn("Can't get delegators count for ", chainName)
  }

  return delegatorsCount
}

// export const getTotalStaked = async () => {
//   const results = await Promise.all(
//     mainnetChains.map(async (chain) => {
//       const validatorInfo = chain.registryName
//         ? await getValidatorInfo(chain.registryName, chain.valoper, chain.exponent, chain.apiUrl)
//         : { bondedTokens: 0 }
//       const bondedTokens = validatorInfo.bondedTokens
//       const price = chain.coinId ? await getPrice(chain.coinId) : 0
//       return bondedTokens * price
//     })
//   )
//   return Math.round(results.reduce((prev, curr) => prev + curr))
// }
//
// export const getTotalDelegatorsCount = async () => {
//   const results = await Promise.all(
//     mainnetChains.map(async (chain) =>
//       chain.registryName ? await getDelegatorsCount(chain.registryName, chain.valoper, chain.apiUrl) : 0
//     )
//   )
//   return results.reduce((prev, curr) => prev + curr)
// }
