import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import RedirectToApp from 'Components/RedirectToApp'
import Dashboard from 'Pages/Dashboard'
import Home from 'Pages/Home'
import * as React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { useThemeContext } from 'Theme/ThemeContext'

const App = () => {
  const { theme } = useThemeContext()

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter basename={`/${process.env.PUBLIC_URL}`}>
        <Routes>
          <Route
            path="/"
            element={<Home />}
          />
          <Route
            path="/dashboard"
            element={<Dashboard />}
          />
          <Route
            path="*"
            element={<RedirectToApp />}
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
