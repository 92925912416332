import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import TableContainer from '@mui/material/TableContainer'
import Typography from '@mui/material/Typography'
import NjLogo from 'Assets/img/nj_logo.svg'
import MainLayout from 'Components/MainLayout'
import { useEffect, useState } from 'react'
import { getDirectoryChainApr, getSupportedChains, getValidatorInfo } from 'Services/chainApi'
import { getPricesForArray } from 'Services/coingeckoApi'
import { getImageUrl } from 'Services/utils'

const Dashboard = () => {
  const [chains, setChains] = useState([])

  useEffect(() => {
    ;(async () => {
      const supportedChains = (await getSupportedChains()).filter((chain) => chain.chain_name !== 'firmachain')
      const coingeckoIds = supportedChains.map((chain) => chain.coingecko_id).filter((id) => id)
      const prices = await getPricesForArray(coingeckoIds)

      const validatorInfos = await Promise.all(
        supportedChains.map(async (chain) => {
          const denom = chain.staking?.staking_tokens[0]?.denom || chain.fees?.fee_tokens[0]?.denom
          const denom_exponent = chain.denom_exponent ? chain.denom_exponent : denom?.startsWith('a') ? 18 : 6
          const validatorInfo = await getValidatorInfo(
            chain.chain_name,
            chain.validator.address,
            denom_exponent,
            chain.apiUrl
          )
          return {
            id: chain.chain_name,
            commissionRate: validatorInfo.commissionRate,
            totalStaked: validatorInfo.bondedTokens || 0,
            status: validatorInfo.status,
          }
        })
      )

      const chainsWithValidatorInfo = await Promise.all(
        supportedChains.map(async (chain) => {
          const validatorInfo = validatorInfos.find((info) => info.id === chain.chain_name)
          const price = prices[chain.coingecko_id]?.usd || 0
          const apr = await getDirectoryChainApr(chain.chain_name)
          return {
            ...chain,
            validatorInfo,
            price,
            ...apr,
          }
        })
      )

      setChains(chainsWithValidatorInfo)
    })()
  }, [])

  const getInfo = (chain) => {
    const apr = chain.calculated_apr
    const yearlyIncome =
      chain.validatorInfo.status === 'BOND_STATUS_BONDED'
        ? chain.validatorInfo.totalStaked * apr * chain.validatorInfo.commissionRate * chain.price
        : 0
    const monthlyIncome = yearlyIncome / 12
    const dailyIncome = yearlyIncome / 365

    return {
      id: chain.chain_name,
      logoUrl: getImageUrl(chain.logo),
      name: chain.pretty_name,
      totalStaked: Math.floor(chain.validatorInfo.totalStaked),
      totalStakedInUsd: Math.floor(chain.validatorInfo.totalStaked * chain.price),
      commissionRate: Math.floor(chain.validatorInfo.commissionRate * 100),
      dailyIncome,
      monthlyIncome,
      yearlyIncome,
    }
  }

  const rows = chains.map((chain) => getInfo(chain)).sort((chain1, chain2) => chain2.yearlyIncome - chain1.yearlyIncome)

  const totalRow = rows.reduce(
    (previousValue, currentValue) => ({
      totalStakedInUsd: previousValue.totalStakedInUsd + currentValue.totalStakedInUsd,
      dailyIncome: previousValue.dailyIncome + currentValue.dailyIncome,
      monthlyIncome: previousValue.monthlyIncome + currentValue.monthlyIncome,
      yearlyIncome: previousValue.yearlyIncome + currentValue.yearlyIncome,
    }),
    {
      totalStakedInUsd: 0,
      dailyIncome: 0,
      monthlyIncome: 0,
      yearlyIncome: 0,
    }
  )
  totalRow.id = 'total'
  totalRow.name = 'Total'
  totalRow.logoUrl = NjLogo

  rows.push(totalRow)

  return (
    <MainLayout>
      <Box
        paddingY={5}
        sx={(theme) => ({
          backgroundColor: theme.palette.background.BG1,
        })}
      >
        <Container maxWidth="lg">
          <Box
            display="flex"
            flexDirection="column"
            gap={3}
          >
            <Typography variant="h2">Dashboard</Typography>
            <TableContainer component={Paper}>
              <Table
                aria-label="simple table"
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Network</TableCell>
                    <TableCell align="right">Staked, tokens</TableCell>
                    <TableCell align="right">Staked, $</TableCell>
                    <TableCell align="right">Commission, %</TableCell>
                    <TableCell align="right">Daily income</TableCell>
                    <TableCell align="right">Monthly income</TableCell>
                    <TableCell align="right">Yearly income</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((chainInfo) => (
                    <TableRow key={chainInfo.id}>
                      <TableCell
                        component="th"
                        scope="row"
                      >
                        <Box
                          component="img"
                          src={chainInfo.logoUrl}
                          height={20}
                          width={20}
                          sx={(theme) => ({
                            borderRadius: `${theme.shape.borderRadius}px`,
                          })}
                        />
                      </TableCell>
                      <TableCell>{chainInfo.name}</TableCell>
                      <TableCell align="right">{chainInfo.totalStaked}</TableCell>
                      <TableCell align="right">{chainInfo.totalStakedInUsd.toLocaleString()}</TableCell>
                      <TableCell align="right">{chainInfo.commissionRate}</TableCell>

                      <TableCell align="right">{chainInfo.dailyIncome.toFixed(2)}</TableCell>
                      <TableCell align="right">{chainInfo.monthlyIncome.toFixed(2)}</TableCell>
                      <TableCell align="right">{chainInfo.yearlyIncome.toFixed(2)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Container>
      </Box>
    </MainLayout>
  )
}

export default Dashboard
