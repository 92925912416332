import 'react-alice-carousel/lib/alice-carousel.css'
import './carousel.css'

import MentionPreviewCard from 'Components/MentionsCarousel/MentionPreviewCard'
import AliceCarousel from 'react-alice-carousel'

export const MENTION_TYPES = {
  TWITTER: 'twitter',
  MEDIUM: 'meium',
  OTHER: 'other',
}

const mentions = [
  {
    type: MENTION_TYPES.TWITTER,
    url: 'https://twitter.com/humansdotai/status/1632705560616353792',
    author: '@humansdotai',
    description:
      "Humans Validators are independent operators distributed worldwide, working tirelessly to keep our ecosystem safe & running smoothly. With their help, we're one step closer to accomplishing a unique mission:the first blockchain where AIs can thrive & evolve",
  },
  {
    type: MENTION_TYPES.TWITTER,
    author: '@OLLOStation',
    url: 'https://twitter.com/OLLOStation/status/1633129704495665153',
    description:
      'Announcing the #Mainnet Genesis Validators 📢\nAfter meticulous consideration, the Station 8 Team is thrilled to present the first round of mainnet genesis validators.',
  },
  {
    type: MENTION_TYPES.MEDIUM,
    author: 'Uptick Network',
    url: 'https://blog.uptickproject.com/uptick-network-and-nodejumper-reach-strategic-cooperation-to-jointly-build-the-uptick-2d1063b85eaf',
    description:
      'As a strategic partner, Nodejumper will collaborate with Uptick Network in public relations efforts to promote awareness of Uptick Network in the community and bring in delegation from the Nodejumper community. Uptick Network will also provide the best support to Nodejumper on delegation service and activities in the community.',
  },
  {
    type: MENTION_TYPES.TWITTER,
    author: '@nodejumper',
    url: 'https://twitter.com/nodejumper/status/1618543658432888837',
    description:
      'Happy to participate in the upcoming @dymensionXYZ testnet!\n Dymension empowers people to easily deploy their own lightning fast blockchains, called RollApps 🚀🚀🚀',
  },
  {
    type: MENTION_TYPES.MEDIUM,
    author: 'StaFi_Protocol',
    url: 'https://medium.com/stafi/introducing-stafihub-mainnet-validator-nodejumper-336120b96463',
    description:
      'The Nodejumper.io is a professional PoS validator that is focused on blockchain consumers & validators, helping them to easily join the Cosmos SDK community and make it bigger and more valuable. The validation experience includes 20+ chains — testnets and mainnets.',
  },
  {
    type: MENTION_TYPES.TWITTER,
    author: '@CascadiaSystems',
    url: 'https://twitter.com/CascadiaSystems/status/1661991668864913408',
    description:
      "This month's Community Spotlight features @nodejumper, a top-tier team with deep experience in the @cosmos ecosystem.",
  },
]

const responsive = {
  0: { items: 1 },
  900: { items: 2 },
  1200: { items: 3 },
}

const MentionsCarousel = () => {
  const items = mentions.map((mention) => (
    <MentionPreviewCard
      key={mention.url}
      mention={mention}
    />
  ))

  return (
    <AliceCarousel
      autoPlay
      autoPlayInterval={3000}
      autoPlayStrategy="all"
      infinite
      mouseTracking
      disableButtonsControls
      responsive={responsive}
      items={items}
    />
  )
}

export default MentionsCarousel
