import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Modal from '@mui/material/Modal'
import Skeleton from '@mui/material/Skeleton'
import Calculator from 'Components/ChainGrid/Calculator'
import ChainCard from 'Components/ChainGrid/ChainCard'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { getSupportedChains } from 'Services/chainApi'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: 400,
    sm: 600,
  },
}

const ChainGrid = () => {
  const [chains, setChains] = useState([])
  const [isModalOpen, setModalOpen] = useState(false)
  const [selectedChain, setSelectedChain] = useState(null)

  useEffect(() => {
    ;(async () => {
      const supportedChains = await getSupportedChains()
      setChains(supportedChains)
    })()
  }, [])

  const handleModalOpen = (chain) => {
    setSelectedChain(chain)
    setModalOpen(true)
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
      >
        <Box sx={modalStyle}>
          <Calculator
            chain={selectedChain}
            onClose={handleModalClose}
          />
        </Box>
      </Modal>

      <Grid
        container
        spacing={3}
      >
        {chains.length > 0 &&
          chains.map((chain) => (
            <Grid
              key={chain.chain_name}
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
            >
              <ChainCard
                chain={chain}
                onModalOpen={handleModalOpen}
              />
            </Grid>
          ))}
        {chains.length === 0 &&
          Array(16)
            .fill()
            .map((_, index) => (
              <Grid
                key={index}
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
              >
                <Skeleton
                  key={index}
                  variant="rounded"
                  height={210}
                />
              </Grid>
            ))}
      </Grid>
    </>
  )
}

export default ChainGrid
