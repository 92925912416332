import { createTheme } from '@mui/material/styles'

const common = {
  typography: {
    fontFamily: ['Montserrat'],
    h1: {
      fontWeight: 600,
    },
    h2: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 500,
    },
  },
  palette: {
    blue: {
      main: '#2b57c9',
    },
  },
  shape: {
    borderRadius: 5,
  },
}

const lightTheme = createTheme({
  ...common,
  palette: {
    ...common.palette,
    mode: 'light',
    primary: {
      main: '#000000',
    },
    background: {
      BG1: '#FAFAFA',
      BG2: '#F5F5F5',
      paper: '#FFFFFF',
    },
  },
})

const darkTheme = createTheme({
  ...common,
  palette: {
    ...common.palette,
    mode: 'dark',
    primary: {
      main: '#FFFFFF',
    },
    background: {
      BG1: '#1D1D1D',
      BG2: '#212121',
      paper: '#2B2B2B',
    },
  },
})

export { darkTheme, lightTheme }
