import Box from '@mui/material/Box'
import Footer from 'Components/Footer'
import Header from 'Components/Header'
import * as React from 'react'

const MainLayout = ({ children }) => (
  <Box
    display="flex"
    flexDirection="column"
  >
    <Header />
    {children}
    <Footer />
  </Box>
)

export default MainLayout
