import { useTheme } from '@mui/material/'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import ScrollIcon from 'Components/ScrollIcon'
import * as React from 'react'
import { useEffect } from 'react'
import { scrollToElement } from 'Services/utils'
import DOTS from 'vanta/dist/vanta.dots.min'

const scrollIconStyles = (theme, isScrolled) => ({
  position: 'absolute',
  bottom: '20px',
  left: '50%',
  transform: 'translate(-50%, 0)',
  opacity: isScrolled ? 0 : 1,
  transition: theme.transitions.create(['opacity']),
})

const HeroSection = () => {
  const [vantaEffect, setVantaEffect] = React.useState(0)
  const vantaRef = React.useRef(null)
  const theme = useTheme()

  const isScrolled = useScrollTrigger({
    disableHysteresis: true,
    threshold: 70,
  })

  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect()
      DOTS({
        el: vantaRef.current,
        mouseControls: true,
        touchControls: true,
        gyroControls: true,
        minHeight: 200.0,
        minWidth: 200.0,
        scale: 1.0,
        size: 4.5,
        scaleMobile: 1.0,
        spacing: 35.0,
        showLines: false,

        backgroundColor: theme.palette.background.BG2,
        color: theme.palette.blue.main,
      })
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy()
    }
  }, [vantaEffect, theme])

  return (
    <Box
      id="top"
      ref={vantaRef}
      height="calc(100vh - 65px)"
      position="relative"
    >
      <Container
        maxWidth="lg"
        sx={{
          height: '100%',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100%"
          gap={2}
        >
          <Typography
            variant="h2"
            sx={{
              textAlign: 'center',
            }}
          >
            Trusted Passive Income Provider
          </Typography>
          <Typography
            variant="h5"
            sx={{
              textAlign: 'center',
            }}
          >
            Stake your assets with secure non-custodial staking powered by NODEJUMPER validator nodes
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            paddingTop={2}
            gap={3}
          >
            <Button
              variant="contained"
              size="large"
              onClick={() => scrollToElement('networks')}
            >
              Stake with us
            </Button>
            <Button
              variant="outlined"
              href="https://app.nodejumper.io"
              target="blank"
              size="large"
              sx={{
                backdropFilter: 'blur(8px)',
              }}
            >
              Launch App
            </Button>
          </Box>
        </Box>
        <ScrollIcon sx={(theme) => scrollIconStyles(theme, isScrolled)} />
      </Container>
    </Box>
  )
}

export default HeroSection
