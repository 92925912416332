import { NJ_APP_PREFIX } from 'Services/utils'

const RedirectToApp = () => {
  const pathname = window.location.pathname
  const hash = window.location.hash

  window.location.href = NJ_APP_PREFIX + pathname + hash
}

export default RedirectToApp
