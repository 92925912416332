import CalculateIcon from '@mui/icons-material/Calculate'
import StarIcon from '@mui/icons-material/Star'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import PaperCustom from 'Components/PaperCustom'
import { useEffect, useState } from 'react'
import { getDisplayApr, getImageUrl, getRestakeUrl } from 'Services/utils'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 150,
  },
  genesisIcon: (theme) => ({
    alignSelf: 'flex-start',
    marginLeft: 'auto',
    opacity: 0.2,
    transition: theme.transitions.create(['opacity', 'color']),
    '&:hover': {
      opacity: 1,
      color: theme.palette.blue.main,
    },
  }),
}

const ChainCard = ({ chain, onModalOpen }) => {
  const [apr, setApr] = useState(null)

  const {
    chain_name: id,
    pretty_name,
    logo,
    apr: hardcodedApr,
    validator: { address: valoper, genesis, stake_url } = {},
  } = chain

  const chainName = pretty_name || id
  const aprElement = apr ? `~${apr}%` : 'soon'
  const restakeUrl = stake_url ? stake_url : getRestakeUrl(id, valoper)
  const logoUrl = getImageUrl(logo)

  useEffect(() => {
    ;(async () => {
      const chainApr = await getDisplayApr(id, hardcodedApr)
      setApr(chainApr)
    })()
  }, [id, hardcodedApr])

  const handleCalculatorClick = () => {
    if (apr) {
      onModalOpen(chain)
    }
  }

  const handleStakeClick = (event) => {
    event.stopPropagation()
  }

  return (
    <PaperCustom
      animated
      sx={styles.container}
      onClick={handleCalculatorClick}
    >
      <Box
        display="flex"
        alignItems="center"
        gap={1}
      >
        <Box
          component="img"
          src={logoUrl}
          height={40}
          width={40}
          sx={(theme) => ({
            borderRadius: `${theme.shape.borderRadius}px`,
          })}
        />
        <Typography variant="h5">{chainName}</Typography>
        {genesis && (
          <Tooltip
            title="Genesis validator"
            placement="top"
            arrow
          >
            <StarIcon
              fontSize="small"
              sx={styles.genesisIcon}
            />
          </Tooltip>
        )}
      </Box>
      <Typography
        variant="h6"
        sx={(theme) => ({
          alignItems: 'center',
          paddingY: theme.spacing(1),
        })}
      >
        {'APR: '}
        {aprElement}
      </Typography>
      <Box
        display="flex"
        gap={1}
      >
        <Button
          variant="outlined"
          href={restakeUrl}
          target="_blank"
          sx={{
            flexGrow: 1,
          }}
          onClick={handleStakeClick}
        >
          Stake!
        </Button>
        {apr > 0 && (
          <Tooltip
            title="Calculate your earnings"
            placement="top"
            arrow
          >
            <IconButton onClick={handleCalculatorClick}>
              <CalculateIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </PaperCustom>
  )
}

export default ChainCard
