import App from 'App'
import * as React from 'react'
import { createRoot } from 'react-dom/client'
import { ThemeContextProvider } from 'Theme/ThemeContext'

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

root.render(
  <ThemeContextProvider>
    <App />
  </ThemeContextProvider>
)
