import MenuIcon from '@mui/icons-material/Menu'
import { alpha } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import nodejumperLogo from 'Assets/img/nj_logo.svg'
import ThemeSwitcher from 'Components/ThemeSwitcher'
import * as React from 'react'
import { useState } from 'react'
import { scrollToElement } from 'Services/utils'

const menuItems = [
  {
    label: 'Products',
    anchor: 'products',
  },
  {
    label: 'Networks',
    anchor: 'networks',
  },
  {
    label: 'Contacts',
    anchor: 'contacts',
  },
]

const styles = {
  drawer: {
    display: {
      sm: 'block',
      md: 'none',
    },
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      paddingTop: '65px',
    },
  },
  appBar: (theme, isScrolled) => ({
    backdropFilter: 'saturate(180%) blur(16px)',
    background: `${alpha(theme.palette.background.paper, isScrolled ? 0.72 : 1)}`,
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.divider}`,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['background-color']),
  }),
}

const Header = (props) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false)

  const isScrolled = useScrollTrigger({
    disableHysteresis: true,
    threshold: 65,
  })

  const handleDrawerToggle = () => {
    setDrawerOpen((prevState) => !prevState)
  }

  return (
    <>
      <AppBar
        id="header"
        component="nav"
        position="sticky"
        sx={(theme) => styles.appBar(theme, isScrolled)}
      >
        <Container maxWidth="lg">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              display="flex"
              alignItems="center"
              sx={{
                cursor: 'pointer',
              }}
              onClick={() => scrollToElement('top')}
            >
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                disableRipple
              >
                <Box
                  component="img"
                  src={nodejumperLogo}
                  alt="Stake with NODEJUMPER!"
                  width={40}
                  height={40}
                />
              </IconButton>
              <Typography
                variant="h5"
                color="primary"
              >
                NODEJUMPER
              </Typography>
            </Box>
            <Box
              height="fit-content"
              gap={2}
              sx={{
                display: {
                  xs: 'none',
                  md: 'flex',
                },
              }}
            >
              {menuItems.map((item) => (
                <Button
                  key={item.anchor}
                  onClick={() => scrollToElement(item.anchor)}
                >
                  {item.label}
                </Button>
              ))}
              <Button
                variant="contained"
                href="https://app.nodejumper.io"
              >
                Launch App
              </Button>
              <ThemeSwitcher />
            </Box>
            <Box
              display="flex"
              sx={{
                display: { md: 'none' },
              }}
            >
              <ThemeSwitcher />
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Box>
        </Container>
      </AppBar>
      <Drawer
        anchor="top"
        variant="temporary"
        open={isDrawerOpen}
        onClose={handleDrawerToggle}
        disableScrollLock={true}
        ModalProps={{
          keepMounted: true,
        }}
        sx={styles.drawer}
      >
        <Box
          onClick={handleDrawerToggle}
          sx={{ textAlign: 'center' }}
        >
          <List>
            {menuItems.map((item) => (
              <ListItem
                key={item.anchor}
                disablePadding
                onClick={() => scrollToElement(item.anchor)}
              >
                <ListItemButton sx={{ textAlign: 'center' }}>
                  <ListItemText>{item.label}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  )
}

export default Header
