import { createContext, useContext } from 'react'
import { usePersistedState } from 'Services/utils'
import { darkTheme, lightTheme } from 'Theme/theme'

const ThemeContext = createContext()

export const useThemeContext = () => useContext(ThemeContext)

export const ThemeContextProvider = ({ children }) => {
  const [theme, setTheme] = usePersistedState('theme', 'light')

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'))
  }

  return (
    <ThemeContext.Provider value={{ theme: theme === 'light' ? lightTheme : darkTheme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}
