import Grid from '@mui/material/Grid'
import CmdImage from 'Assets/img/cmd.webp'
import DecentralizationMapImage from 'Assets/img/decentalization_map.webp'
import IbcImage from 'Assets/img/IBC.webp'
import MonitoringToolImage from 'Assets/img/monitoring_tool.webp'
import NjAppImage from 'Assets/img/nj_app.webp'
import ProposalBotImage from 'Assets/img/proposal_bot.webp'
import ProductCard from 'Components/ProductsGrid/ProductCard'
import * as React from 'react'

const ProductsGrid = () => (
  <Grid
    container
    spacing={3}
    justifyContent="center"
  >
    <Grid
      item
      xs={12}
      md={6}
      lg={4}
    >
      <ProductCard
        title="Cosmos Interchain Utility"
        description="Huge variety of utility tools and data analytics for the Cosmos Ecosystem"
        image={NjAppImage}
        link="https://app.nodejumper.io"
      />
    </Grid>
    <Grid
      item
      xs={12}
      md={6}
      lg={4}
    >
      <ProductCard
        title="Decentralization Map"
        description="Overview of physical node geolocation"
        image={DecentralizationMapImage}
        link="https://app.nodejumper.io/decentralization-map"
      />
    </Grid>
    <Grid
      item
      xs={12}
      md={6}
      lg={4}
    >
      <ProductCard
        title="Cheat Sheet"
        description="Predefined set of commonly used CLI commands"
        image={CmdImage}
        link="https://app.nodejumper.io/andromeda/cheat-sheet"
      />
    </Grid>
    <Grid
      item
      xs={12}
      md={6}
      lg={4}
    >
      <ProductCard
        title="Cosmos Telegram Proposal Bot"
        description="A telegram bot to notify users of governance proposals when they're available for voting"
        image={ProposalBotImage}
        link="https://t.me/nodejumper_governance_bot"
      />
    </Grid>
    <Grid
      item
      xs={12}
      md={6}
      lg={4}
    >
      <ProductCard
        title="Monitoring Tool"
        description="A powerful and easy-to-use monitoring tool for server hardware and validator nodes with alerts via telegram bot"
        image={MonitoringToolImage}
        link="https://github.com/nodejumper-org/monitoring-tool"
      />
    </Grid>
    <Grid
      item
      xs={12}
      md={6}
      lg={4}
    >
      <ProductCard
        title="IBC Relayers"
        description="Interchain communication support "
        image={IbcImage}
        link="https://relayers.smartstake.io/relayer/FFB0AA51A2DF5954"
      />
    </Grid>
  </Grid>
)

export default ProductsGrid
