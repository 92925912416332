import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import AdvantagesGrid from 'Components/AdvantagesGrid'
import ChainGrid from 'Components/ChainGrid'
import HeroSection from 'Components/HeroSection'
import MainLayout from 'Components/MainLayout'
import MentionsCarousel from 'Components/MentionsCarousel'
import ProductsGrid from 'Components/ProductsGrid'
import StatisticsGrid from 'Components/StatisticsGrid'
import * as React from 'react'

// const [networksCount, setNetworksCount] = useState(15)
// const [totalStaked, setTotalStaked] = useState(2000000)
// const [delegatorsCount, setDelegatorsCount] = useState(0)

// useEffect(() => {
//   setNetworksCount(mainnetChains.length)
//
//   const fetchTotalStaked = async () => {
//     const staked = await getTotalStaked()
//     setTotalStaked(staked)
//   }
//   fetchTotalStaked().catch(() => console.error("Can't get total staked amount"))
//
//   const fetchDelegatorsCount = async () => {
//     const delegators = await getTotalDelegatorsCount()
//     setDelegatorsCount(delegators)
//   }
//   fetchDelegatorsCount().catch(() => console.error("Can't get total delegators count"))
// }, [])

const Home = () => (
  <MainLayout>
    <HeroSection />

    <Box
      id="stats"
      paddingY={8}
      sx={(theme) => ({
        backgroundColor: theme.palette.background.BG1,
      })}
    >
      <Container maxWidth="lg">
        <StatisticsGrid />
      </Container>
    </Box>

    <Box
      id="advantages"
      paddingY={8}
      sx={(theme) => ({
        backgroundColor: theme.palette.background.BG2,
      })}
    >
      <Container maxWidth="lg">
        <AdvantagesGrid />
      </Container>
    </Box>

    <Box
      id="products"
      paddingY={8}
      sx={(theme) => ({
        backgroundColor: theme.palette.background.BG1,
      })}
    >
      <Container maxWidth="lg">
        <Box paddingBottom={3}>
          <Typography variant="h2">Our products and services</Typography>
        </Box>
        <ProductsGrid />
      </Container>
    </Box>

    <Box
      id="networks"
      paddingY={8}
      sx={(theme) => ({
        backgroundColor: theme.palette.background.BG2,
      })}
    >
      <Container maxWidth="lg">
        <Box paddingBottom={3}>
          <Typography variant="h2">Networks</Typography>
        </Box>
        <ChainGrid />
      </Container>
    </Box>

    <Box
      id="networks"
      paddingY={8}
      sx={(theme) => ({
        backgroundColor: theme.palette.background.BG1,
      })}
    >
      <Container maxWidth="lg">
        <Box paddingBottom={3}>
          <Typography variant="h2">Blog</Typography>
        </Box>
        <MentionsCarousel />
      </Container>
    </Box>
  </MainLayout>
)

export default Home
