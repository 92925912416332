import { Skeleton } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { getSupportedChains, getValidatorStatistic } from 'Services/chainApi'

const styles = {
  label: {
    textAlign: 'center',
  },
}

const communityMembersCount = 2500

const StatisticsGrid = () => {
  const [networksCount, setNetworksCount] = useState(25)
  const [statistic, setStatistic] = useState(null)

  useEffect(() => {
    ;(async () => {
      const [supportedChains, validatorStatistic] = await Promise.all([getSupportedChains(), getValidatorStatistic()])
      setNetworksCount(supportedChains.length)
      setStatistic(validatorStatistic)
    })()
  }, [])

  return (
    <Grid
      container
      spacing={5}
      justifyContent="center"
    >
      <Grid
        item
        xs={12}
        md={4}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={1}
        >
          <Typography
            variant="h5"
            sx={styles.label}
          >
            Total assets staked
          </Typography>

          {statistic?.totalStaked ? (
            <Typography variant="h3">{`$${(statistic?.totalStaked + 10000000).toLocaleString()}`}</Typography>
          ) : (
            <Skeleton
              width={250}
              height={70}
            />
          )}
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={1}
        >
          <Typography
            variant="h5"
            sx={styles.label}
          >
            Supported networks
          </Typography>
          {statistic ? (
            <Typography variant="h3">{networksCount}</Typography>
          ) : (
            <Skeleton
              width={100}
              height={70}
            />
          )}
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={1}
        >
          <Typography
            variant="h5"
            sx={styles.label}
          >
            Relayers
          </Typography>
          {statistic?.relayersCount ? (
            <Typography variant="h3">{`${statistic?.relayersCount}`}</Typography>
          ) : (
            <Skeleton
              width={100}
              height={70}
            />
          )}
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={1}
        >
          <Typography
            variant="h5"
            sx={styles.label}
          >
            Number of delegators
          </Typography>
          {statistic?.delegatorsCount ? (
            <Typography variant="h3">{`${statistic?.delegatorsCount}`}</Typography>
          ) : (
            <Skeleton
              width={150}
              height={70}
            />
          )}
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={1}
        >
          <Typography variant="h5">Community members</Typography>
          {statistic ? (
            <Typography variant="h3">{`${communityMembersCount}+`}</Typography>
          ) : (
            <Skeleton
              width={150}
              height={70}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  )
}

export default StatisticsGrid
