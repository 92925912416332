import Box from '@mui/material/Box'
import { forwardRef } from 'react'

const styles = {
  container: (theme) => ({
    display: 'flex',
    width: '100%',
    height: '100%',
    padding: '30px',
    boxShadow: theme.shadows[2],
    borderRadius: `${theme.shape.borderRadius * 2}px`,
    backgroundColor: theme.palette.background.paper,
  }),
  transition: (theme) => ({
    cursor: 'pointer',
    transition: theme.transitions.create(['box-shadow', 'transform']),
    '&:hover': {
      transform: 'scale(1.02)',
      boxShadow: theme.shadows[4],
    },
  }),
}

const PaperCustom = forwardRef(({ animated, sx, children, ...props }, ref) => (
  <Box
    sx={[styles.container, animated && styles.transition, sx && sx]}
    ref={ref}
    {...props}
  >
    {children}
  </Box>
))

export default PaperCustom
