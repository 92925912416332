import CloseIcon from '@mui/icons-material/Close'
import { Divider, Skeleton, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Slider from '@mui/material/Slider'
import Typography from '@mui/material/Typography'
import PaperCustom from 'Components/PaperCustom'
import { useEffect, useState } from 'react'
import { getValidatorInfo } from 'Services/chainApi'
import { getPrice } from 'Services/coingeckoApi'
import { getApr, getImageUrl, getRestakeUrl } from 'Services/utils'

const styles = {
  container: (theme) => ({
    padding: theme.spacing(5),
    width: {
      xs: 400,
      sm: 600,
    },
  }),
  stakeContainer: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    width: {
      xs: 130,
      sm: 'unset',
    },
    gap: theme.spacing(1),
  }),
  textLabel: {
    flex: 0.7,
  },
  textValue: {
    flex: 1,
    textAlign: 'end',
    fontWeight: 500,
  },
}

const Calculator = ({ chain, onClose }) => {
  const {
    chain_name: id,
    pretty_name,
    logo,
    apr: hardcodedApr,
    coingecko_id,
    symbol: ticker,
    params,
    validator: { address: validatorAddress, commissionRate, stake_url },
    apiUrl, // todo: get custom api url if needed. default should be https://api.nodejumper.io/${id}
  } = chain

  const [staked, setStaked] = useState(1000)
  const [stakedInUsd, setStakedInUsd] = useState(0)
  const [validatorInfo, setValidatorInfo] = useState({})
  const [apr, setApr] = useState(0)
  const [priceInUsd, setPriceInUsd] = useState(0)

  const unbondingTime = (params?.unbonding_time || 0) / 86400
  const displayApr = apr ? (apr * 100).toFixed(1) : null
  const link = stake_url ? stake_url : getRestakeUrl(id, validatorAddress)
  const logoUrl = getImageUrl(logo)
  const validatorCommissionRate = validatorInfo?.commissionRate * 100 || 5

  const dailyInUsd = Math.floor((staked * apr * priceInUsd) / 365)
  const monthlyInUsd = Math.floor((staked * apr * priceInUsd) / 12)
  const yearlyInUsd = Math.floor(staked * apr * priceInUsd)
  const daily = Math.floor((staked * apr) / 365)
  const monthly = Math.floor((staked * apr) / 12)
  const yearly = Math.floor(staked * apr)

  useEffect(() => {
    ;(async () => {
      const validatorInfo = await getValidatorInfo(id, validatorAddress, 0, apiUrl)

      if (commissionRate && !validatorInfo.commissionRate) {
        setValidatorInfo({
          commissionRate,
        })
      } else {
        setValidatorInfo(validatorInfo)
      }
    })()
  }, [id, validatorAddress, apiUrl, commissionRate])

  useEffect(() => {
    ;(async () => {
      const chainApr = await getApr(id, hardcodedApr, apiUrl)
      setApr(chainApr)
    })()
  }, [id, hardcodedApr, apiUrl])

  useEffect(() => {
    ;(async () => {
      const price = await getPrice(coingecko_id)
      setPriceInUsd(price)
    })()
  }, [coingecko_id])

  useEffect(() => {
    setStakedInUsd((staked * priceInUsd).toFixed(2))
  }, [staked, priceInUsd])

  const handleTextEditChange = (event) => {
    const stakedNumber = Number(event.target.value)
    setStaked(stakedNumber)
    setStakedInUsd((stakedNumber * priceInUsd).toFixed(2))
  }

  const handleUsdTextEditChange = (event) => {
    const stakedInUsdNumber = Number(event.target.value)
    const tokens = Math.floor((stakedInUsdNumber / priceInUsd).toFixed(2))
    setStakedInUsd(stakedInUsdNumber)
    setStaked(tokens)
  }

  const handleSliderChange = (event, newValue) => {
    setStaked(newValue)
    setStakedInUsd((newValue * priceInUsd).toFixed(2))
  }

  return (
    <PaperCustom sx={styles.container}>
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        gap={4}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            alignItems="center"
            gap={1}
          >
            <Box
              component="img"
              src={logoUrl}
              height={40}
              width={40}
              borderRadius={1}
            />
            <Typography variant="h5">{pretty_name}</Typography>
          </Box>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Typography variant="h5">Rules</Typography>
          <Box
            display="flex"
            justifyContent="space-between"
          >
            <Typography>Validator fee</Typography>
            {validatorCommissionRate ? <Typography>{validatorCommissionRate}%</Typography> : <Skeleton width={25} />}
          </Box>
          <Divider />
          <Box
            display="flex"
            justifyContent="space-between"
          >
            <Typography>Estimated earn per year (APR)</Typography>
            {displayApr ? (
              <Typography
                variant="h5"
                sx={(theme) => ({
                  color: theme.palette.success.dark,
                })}
              >
                {displayApr}%
              </Typography>
            ) : (
              <Skeleton
                width={50}
                height={32}
              />
            )}
          </Box>
          <Divider />
          <Box
            display="flex"
            justifyContent="space-between"
          >
            <Typography>Undelegate period</Typography>
            <Typography>{unbondingTime} days</Typography>
          </Box>
        </Box>

        <Box
          dispaly="flex"
          flexDirection="column"
          marginBottom={-1}
          gap={1}
        >
          <Typography variant="h5">Your stake</Typography>
          <Box
            display="flex"
            justifyContent="space-between"
          >
            <Box sx={styles.stakeContainer}>
              <TextField
                variant="standard"
                type="number"
                value={staked}
                onChange={handleTextEditChange}
                sx={{
                  fontSize: '30px',
                }}
              />
              <Typography>
                Your {pretty_name} ({ticker}) stake
              </Typography>
            </Box>

            <Box sx={styles.stakeContainer}>
              <TextField
                variant="standard"
                type="number"
                value={stakedInUsd}
                onChange={handleUsdTextEditChange}
                height="100px"
              />
              <Typography>Your {pretty_name} ($) stake</Typography>
            </Box>
          </Box>

          <Slider
            min={1}
            max={100000}
            value={staked}
            onChange={handleSliderChange}
            sx={{
              paddingY: 3,
            }}
          />
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Typography variant="h5">Your rewards</Typography>
          <Box
            display="flex"
            justifyContent="space-between"
          >
            <Typography sx={styles.textLabel}>Daily</Typography>
            <Typography sx={styles.textValue}>
              {daily} {ticker}
            </Typography>
            <Typography sx={styles.textValue}>${dailyInUsd}</Typography>
          </Box>
          <Divider />
          <Box
            display="flex"
            justifyContent="space-between"
          >
            <Typography sx={styles.textLabel}>Monthly</Typography>
            <Typography sx={styles.textValue}>
              {monthly} {ticker}
            </Typography>
            <Typography sx={styles.textValue}>${monthlyInUsd}</Typography>
          </Box>
          <Divider />
          <Box
            display="flex"
            justifyContent="space-between"
            paddingBottom={3}
          >
            <Typography sx={styles.textLabel}>Yearly</Typography>
            <Typography sx={styles.textValue}>
              {yearly} {ticker}
            </Typography>
            <Typography sx={styles.textValue}>${yearlyInUsd}</Typography>
          </Box>
          <Button
            variant="outlined"
            href={link}
            target="_blank"
          >
            Stake!
          </Button>
        </Box>
      </Box>
    </PaperCustom>
  )
}

export default Calculator
