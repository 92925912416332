import Box from '@mui/material/Box'
import { keyframes } from '@mui/system'

const scrollAnimation2 = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  75% {
    transform: translateY(0.75em);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
`

const boxStyles = (theme) => ({
  display: 'block',
  position: 'relative',
  height: '3em',
  width: '1.8em',
  border: `.2em solid ${theme.palette.primary.main}`,
  borderRadius: '1em',
})

const dotStyles = (theme) => ({
  display: 'block',
  position: 'absolute',
  top: '0.6em',
  left: '50%',
  background: theme.palette.primary.main,
  height: '0.5em',
  width: '0.5em',
  marginLeft: '-0.28em',
  borderRadius: '50%',
  transformOrigin: 'top center',
  backfaceVisibility: 'hidden',
  animation: `${scrollAnimation2} 2s ease-out infinite`,
})

const ScrollIcon = (props) => (
  <Box {...props}>
    <Box sx={boxStyles}>
      <Box sx={dotStyles} />
    </Box>
  </Box>
)

export default ScrollIcon
