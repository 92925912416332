import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import PaperCustom from 'Components/PaperCustom'

const styles = {
  container: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
  }),
  image: (theme) => ({
    maxWidth: '100%',
    objectFit: 'cover',
    borderRadius: `${theme.shape.borderRadius}px`,
    maxHeight: {
      xs: '200px',
      sm: '220px',
      lg: '150px',
    },
  }),
}

const ProductCard = ({ title, description, image, link }) => (
  <Link
    href={link}
    color="inherit"
    underline="none"
    target="_blank"
  >
    <PaperCustom
      animated
      sx={styles.container}
    >
      <Typography variant="h5">{title}</Typography>
      <Typography>{description}</Typography>
      <Box
        component="img"
        src={image}
        sx={styles.image}
      />
    </PaperCustom>
  </Link>
)

export default ProductCard
