import GitHubIcon from '@mui/icons-material/GitHub'
import TelegramIcon from '@mui/icons-material/Telegram'
import TwitterIcon from '@mui/icons-material/Twitter'
import { Link } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import SvgIcon from '@mui/material/SvgIcon'
import Typography from '@mui/material/Typography'
import { ReactComponent as MediumIcon } from 'Assets/icon/medium.svg'
import nodejumperLogo from 'Assets/img/nj_logo.svg'
import PaperCustom from 'Components/PaperCustom'
import * as React from 'react'
import { scrollToElement } from 'Services/utils'

const Footer = () => (
  <Box
    sx={(theme) => ({
      backgroundColor: theme.palette.background.BG2,
    })}
  >
    <Container maxWidth="lg">
      <PaperCustom
        id="contacts"
        paddingY={8}
        paddingX={6}
        marginY={8}
      >
        <Grid
          container
          justifyContent="space-between"
          rowSpacing={5}
        >
          <Grid
            item
            sx={{
              display: {
                xs: 'none',
                lg: 'flex',
              },
            }}
            lg={1}
          >
            <IconButton
              size="large"
              disableRipple
              onClick={() => scrollToElement('top')}
              sx={{
                alignItems: 'start',
              }}
            >
              <Box
                component="img"
                src={nodejumperLogo}
                alt="Stake with NODEJUMPER!"
                width={80}
                height={80}
              />
            </IconButton>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            lg={2}
          >
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
            >
              <Typography variant="h5">Community</Typography>
              <Link
                href="https://t.me/nodejumper"
                target="_blank"
              >
                Telegram community
              </Link>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            lg={2}
          >
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
            >
              <Typography variant="h5">Contacts</Typography>
              <Link href="mailto:team@nodejumper.io">team@nodejumper.io</Link>
              <Link href="mailto:team@nodejumper.io">business@nodejumper.io</Link>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            lg={4}
          >
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
            >
              <Typography variant="h5">Social media</Typography>
              <Box
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                gap={2}
              >
                <IconButton
                  href="https://twitter.com/nodejumper"
                  target="_blank"
                >
                  <TwitterIcon fontSize="large" />
                </IconButton>
                <IconButton
                  href="https://github.com/nodejumper-org"
                  target="_blank"
                >
                  <GitHubIcon fontSize="large" />
                </IconButton>
                <IconButton
                  href="https://t.me/nodejumper"
                  target="_blank"
                >
                  <TelegramIcon fontSize="large" />
                </IconButton>
                <IconButton
                  href="https://medium.com/@NODEJUMPER"
                  target="_blank"
                >
                  <SvgIcon fontSize="large">
                    <MediumIcon />
                  </SvgIcon>
                </IconButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </PaperCustom>
    </Container>
  </Box>
)

export default Footer
